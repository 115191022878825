import React from "react";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormGroup,
    Snackbar,
    TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import SettingFormControlLabel from "./SettingFormControlLabel";
import { useNavigate } from "react-router-dom";
import { useServiceSetting } from "../hook/useServiceSetting";
import TodayFortuneSetting from "./TodayFortuneSetting";
import WeatherSetting from "./WeatherSetting";
import axios from "axios";
import { useLocalStorage } from "../context/LocalStorageContext";
import { useUser } from "../context/UserContext";
import { ErrorOutlineIcon } from "../data/icons";
import solarlunar from "solarlunar/src";
import FixedLoading from "./FixedLoading";

export default function ServicesSetting({ user }) {
    const navigate = useNavigate();
    const { token, expoPushToken } = useLocalStorage();
    const { updateUser, updateLastNotification } = useUser();
    const {
        checkTodayFortune,
        setCheckTodayFortune,
        checkWeather,
        setCheckWeather,
        checkNews,
        setCheckNews,
        validationAlertOpen,
        setValidationAlertOpen,
        validationMessages,
        setValidationMessages,
        address,
        setAddress,
        notificationTime,
        setNotificationTime,
        todayFortune,
        setTodayFortune,
    } = useServiceSetting(user);
    const [notExistExpoPushTokenDialogOpen, setNotExistExpoPushTokenDialogOpen] = React.useState(false);
    const [serviceModalLoading, setServiceModalLoading] = React.useState(false);

    const onCloseValidationAlert = () => {
        setValidationAlertOpen(false);
        setValidationMessages([]);
    };

    const saveServiceSettings = () => {
        let validationAlertOpen = false;

        if (!checkTodayFortune && !checkWeather && !checkNews) {
            validationAlertOpen = true;
            setValidationMessages((prevList) => [...prevList, "서비스를 하나 이상 선택해주세요."]);
        }

        if (checkTodayFortune) {
            if (!todayFortune.birthDate || !todayFortune.birthTime || !todayFortune.gender) {
                validationAlertOpen = true;
                if (!todayFortune.birthDate) {
                    setValidationMessages((prevList) => [...prevList, "오늘의 운세 > 생년월일을 입력해주세요."]);
                }
                if (!todayFortune.birthTime) {
                    setValidationMessages((prevList) => [...prevList, "오늘의 운세 > 태어난 시간을 선택해주세요."]);
                }
                if (!todayFortune.gender) {
                    setValidationMessages((prevList) => [...prevList, "오늘의 운세 > 성별을 선택해주세요."]);
                }
            } else if (todayFortune.birthDateError) {
                // TodayFortuneSetting 에서 하는 생년월일 유효성 검증에 걸린 경우
                validationAlertOpen = true;
                setValidationMessages((prevList) => [...prevList, `오늘의 운세 > ${todayFortune.birthDateError}`]);
            } else {
                const inputDate = new Date(todayFortune.birthDate);
                const minDate = new Date("1940-01-01");
                const maxDate = new Date(Date.now() - 86400000);
                if (inputDate < minDate || inputDate > maxDate) {
                    validationAlertOpen = true;
                    setValidationMessages((prevList) => [...prevList, "오늘의 운세 > 생년월일은 1940-01-01 이후이며 어제보다 과거여야 합니다."]);
                }

                // 음력 윤달을 선택한 경우 생년월일이 음력 윤달에 해당하는지 검사
                if (todayFortune.calendarType === "LUNAR_LEAP") {
                    const lunarDate = solarlunar.solar2lunar(
                        new Date(todayFortune.birthDate).getFullYear(),
                        new Date(todayFortune.birthDate).getMonth() + 1,
                        new Date(todayFortune.birthDate).getDate(),
                    );
                    if (!lunarDate.isLeap) {
                        validationAlertOpen = true;
                        validationMessages.push("오늘의 운세 > 음력 윤달을 선택하셨으나 생년월일이 윤달이 아닙니다.");
                    }
                }
            }
        }

        if (checkWeather) {
            if (!address) {
                validationAlertOpen = true;
                setValidationMessages((prevList) => [...prevList, "날씨 > 위치를 설정해주세요."]);
            }
        }

        if (!notificationTime) {
            validationAlertOpen = true;
            setValidationMessages((prevList) => [...prevList, "데일리비서에게 보고 받을 시간을 설정해주세요."]);
        }

        if (validationAlertOpen) {
            setValidationAlertOpen(true);
            return;
        }

        if (!expoPushToken) {
            setNotExistExpoPushTokenDialogOpen(true);
            return;
        }

        updateUserServices(checkTodayFortune, todayFortune, checkWeather, address, checkNews, notificationTime, token, updateUser, user, navigate);
    };

    const updateUserServices = () => {
        setServiceModalLoading(true);

        const requestData = {
            todayFortune: checkTodayFortune
                ? {
                      gender: todayFortune.gender,
                      birthDate: todayFortune.birthDate,
                      calendarType: todayFortune.calendarType,
                      birthTime: todayFortune.birthTime,
                  }
                : null,
            weather: checkWeather ? { address: address } : null,
            news: checkNews ? true : null,
            notificationTime: notificationTime,
        };

        axios
            .post("/api/users/services", requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(async (res) => {
                await updateUser();
                // 컴포넌트 파라미터로 넘어온 user 가 service 가 없었던 경우
                if (user?.services.length === 0) {
                    axios
                        .post(
                            "/api/notifications/welcome-messages",
                            {},
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            },
                        )
                        .then((res) => {
                            updateLastNotification();
                            navigate("/");
                        });
                } else {
                    updateUser();
                    navigate("/");
                }
            })
            .finally(() => {
                setServiceModalLoading(false);
            });
    };

    return (
        <Box sx={{ width: "95vw", fontFamily: "Orbit-Regular" }}>
            <Card variant="contained" sx={{ borderRadius: "12px", marginBottom: "18px" }}>
                <CardContent sx={{ m: 1, p: 2, "&:last-child": { paddingBottom: "16px" } }}>
                    <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "4.3vw" }}>
                        제공받을 서비스를 선택해주세요.
                    </Typography>
                    <FormGroup sx={{ margin: 2, marginBottom: 2 }}>
                        {/*오늘의 운세*/}
                        <SettingFormControlLabel
                            service={{
                                checked: checkTodayFortune,
                                name: "오늘의 운세",
                                tooltipTitle: "생년월일, 성별, 태어난 시간을 기반으로 오늘의 운세를 점쳐드립니다.",
                                onChange: setCheckTodayFortune,
                            }}
                        />
                        {checkTodayFortune && <TodayFortuneSetting todayFortune={todayFortune} setTodayFortune={setTodayFortune} />}
                        {/*날씨*/}
                        <SettingFormControlLabel
                            service={{ checked: checkWeather, name: "날씨", tooltipTitle: "설정하신 위치의 날씨를 알려드립니다.", onChange: setCheckWeather }}
                        />
                        {checkWeather && <WeatherSetting address={address} setAddress={setAddress} />}
                        {/*뉴스*/}
                        <SettingFormControlLabel
                            service={{ checked: checkNews, name: "뉴스", tooltipTitle: "오늘의 헤드라인 뉴스를 알려드립니다.", onChange: setCheckNews }}
                        />
                    </FormGroup>
                </CardContent>
            </Card>
            <Card variant="contained" sx={{ borderRadius: "12px", marginBottom: "25px" }}>
                <CardContent sx={{ m: 1, p: 2, "&:last-child": { paddingBottom: "16px" } }}>
                    <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "4.3vw" }}>
                        보고 받을 시간을 설정해주세요.
                    </Typography>
                    {!user.expoPushToken && (
                        <Typography gutterBottom sx={{ fontSize: "3.3vw" }}>
                            (<span style={{ color: "red", fontWeight: "bold" }}>알림 권한이 없어</span> 알림을 받을 수 없습니다.)
                        </Typography>
                    )}

                    <FormControl fullWidth sx={{ ml: 4, mt: 2, textAlign: "left" }}>
                        <TextField
                            type="time"
                            name={"notificationTime"}
                            value={notificationTime}
                            onChange={(event) => setNotificationTime(event.target.value)}
                            sx={{ width: "80%", borderRadius: 1, fontSize: "1rem" }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: "bold", fontSize: "1rem" },
                            }}
                        />
                    </FormControl>
                </CardContent>
            </Card>
            <Snackbar
                open={validationAlertOpen}
                autoHideDuration={4000}
                onClose={onCloseValidationAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    severity="warning"
                    sx={{ width: "100%", display: "flex", alignItems: "center", fontSize: "1rem", textAlign: "left" }}
                    onClose={onCloseValidationAlert}
                >
                    {validationMessages.map((message) => (
                        <Typography sx={{ m: 1 }}>&bull; {message}</Typography>
                    ))}
                </Alert>
            </Snackbar>
            <Button onClick={saveServiceSettings} variant="contained" sx={{ mb: "18px", width: "50%", height: "50px" }}>
                설정 완료
            </Button>
            <Dialog open={notExistExpoPushTokenDialogOpen} onClose={() => setNotExistExpoPushTokenDialogOpen(false)} aria-labelledby="draggable-dialog-title">
                <DialogTitle style={{ cursor: "move", display: "flex", alignItems: "center" }} id="draggable-dialog-title">
                    <ErrorOutlineIcon style={{ color: "red" }} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ fontFamily: "Pretendard-Regular" }}>
                        데일리비서가 매일 정해진 시간에 알림을 통해 보고 드릴 예정입니다. <span style={{ color: "red", fontWeight: "bold" }}>알림 권한</span>을
                        꼭 허용해주세요.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pt: 0 }}>
                    <Button autoFocus onClick={() => setNotExistExpoPushTokenDialogOpen(false)}>
                        취소
                    </Button>
                    <Button onClick={updateUserServices}>계속하기</Button>
                </DialogActions>
            </Dialog>
            {serviceModalLoading && <FixedLoading />}
        </Box>
    );
}
