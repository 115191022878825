import React, { useEffect, useRef } from "react";
import { Box, Button, Modal, TextField } from "@mui/material";

// 모달 스타일 (화면 하단에서 슬라이드 올라오는 느낌)
const modalStyle = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    transition: "transform 0.3s ease-in-out",
};

export default function ToDoListInputModal({ open, handleClose }) {
    const inputRef = useRef(null); // 입력창에 자동 포커스를 위한 ref

    // 모달이 열릴 때 TextField에 포커스하여 키보드 자동으로 띄우기
    useEffect(() => {
        if (open) {
            // 모달이 열릴 때 약간의 지연을 주고 포커스 설정
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus(); // input창에 포커스
                }
            }, 100); // 100ms 지연
        }
    }, [open]);

    // 날짜 선택할 수 있는거 + 알림 여부 -> 삼성 리마인더 참고
    // 제목
    // 메모
    // 즐겨 찾기
    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: "flex", alignItems: "flex-end" }} // 화면 하단에서 나타나도록 설정
                disableAutoFocus // 기본 포커스를 막고 수동으로 제어
            >
                <Box sx={modalStyle}>
                    <TextField
                        inputRef={inputRef} // 포커스를 위한 ref
                        fullWidth
                        label="할 일을 입력해주세요."
                        variant="outlined"
                    />
                    <Box sx={{ textAlign: "right" }}>
                        <Button sx={{ mt: 2, textAlign: "right" }} variant="contained" onClick={() => alert("click")}>
                            추가하기
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}
