import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "./LocalStorageContext";
import axios from "axios";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const { expoPushToken, token, removeToken } = useLocalStorage();
    const [user, setUser] = useState(null);
    const [lastNotification, setLastNotification] = useState({ id: null, read: null });
    const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가

    useEffect(() => {
        if (token) {
            setIsLoading(true); // 로딩 시작
            axios
                .get("/api/users/me", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    setUser(response.data.content);
                    updateUserExpoPushToken(response.data.content, expoPushToken, setUser);
                    setIsLoading(false); // 로딩 완료
                })
                .catch((error) => {
                    removeToken(); // 에러 시 토큰 제거
                    setIsLoading(false); // 에러 시 로딩 완료
                });

            updateLastNotification();
        } else {
            setIsLoading(false); // 토큰이 없는 경우 로딩 완료
        }
    }, [token, removeToken]);

    useEffect(() => {
        updateUserExpoPushToken(user, expoPushToken, setUser);
    }, [expoPushToken]);

    const updateUser = () => {
        axios
            .get("/api/users/me", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => setUser(response.data.content));
    };

    const updateLastNotification = () => {
        axios
            .get("/api/notification-messages/last", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setLastNotification(response.data.content);
            })
            .catch((error) => {});
    };

    const removeUser = () => {
        setUser(null);
    };

    const deleteUser = () => {
        axios
            .delete("/api/users", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then();
        setUser(null);
    };

    const updateUserExpoPushToken = (user, expoPushToken, setUser) => {
        if (!user) return;

        /**
         * 아래 경우에 user.expoPushToken을 업데이트한다.
         * 1. 알림 권한이 없었다가 생겨서 expoPushToken이 새로 생긴 경우
         * 2. 앱 재설치하여 expoPushToken이 변경된 경우
         */
        if (expoPushToken && (!user.expoPushToken || user.expoPushToken !== expoPushToken)) {
            axios
                .patch(`/api/users/${user.id}`, {
                    expoPushToken: expoPushToken,
                })
                .then((res) => {
                    setUser((current) => ({ ...current, expoPushToken: expoPushToken }));
                });
        }
    };

    return (
        <UserContext.Provider
            value={{
                lastNotification,
                setLastNotification,
                expoPushToken,
                token,
                user,
                updateLastNotification,
                updateUser,
                removeUser,
                deleteUser,
                isLoading,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
