import { Box, List, ListItem } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { convertTo24HourFormat, convertToDateTimeWithoutYearAndTime } from "../../../utils/dateUtils";
import TemperatureChart from "./TemperatureChart";
import { findValueByCategory } from "../../../utils/weatherUtils";
import RecommendedDress from "./RecommendedDress";
import WeatherSummary from "./WeatherSummary";
import WeeklyWeatherItem from "./WeeklyWeatherItem";
import AirQuality from "./AirQuality";

export default function WeatherNotificationDetail({ weatherJson }) {
    const weatherResponse = JSON.parse(weatherJson).content;
    const weathers = weatherResponse.weathers;
    const airQuality = weatherResponse.airQuality;
    const weeklyWeathers = weatherResponse.weeklyWeathers;
    const times = weathers.map((weather) => convertTo24HourFormat(weather.baseDate, weather.baseTime));
    const temperatures = weathers.map((weather) => findValueByCategory(weather.items, "TMP"));

    /**
     * https://icon-icons.com/ko/%EC%95%84%EC%9D%B4%EC%BD%98/sunny-%EC%9D%BC-%EB%82%A0%EC%94%A8-sun/177555
     * 날씨 요약 정보를 만들어서 제공하면 좋을 것 같긴한데... 기획이 필요함
     */
    return weathers.length === 0 ? (
        <Box sx={{ borderRadius: 1, mb: 1 }}>
            <Typography gutterBottom sx={{ mb: 0, fontSize: "5vw", fontFamily: "Pretendard-Regular", fontWeight: "bold" }}>
                날씨
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                }}
            >
                <Typography gutterBottom sx={{ mb: 0, fontSize: "4vw", fontFamily: "Pretendard-Regular" }}>
                    날씨 정보가 없습니다.
                </Typography>
            </Box>
        </Box>
    ) : (
        <Box sx={{ borderRadius: 1, mb: 1 }}>
            <Typography gutterBottom sx={{ mb: 0, fontSize: "5vw", fontFamily: "Pretendard-Regular", fontWeight: "bold" }}>
                날씨
            </Typography>
            <WeatherSummary weatherItems={weathers[0].items} airQuality={airQuality} />
            <Box sx={{ overflowX: "auto", minHeight: "100%", mt: 2 }}>
                <TemperatureChart weathers={weathers} times={times} />
            </Box>
            <Box>
                <RecommendedDress temperatures={temperatures} />
            </Box>
            {airQuality && <AirQuality airQuality={airQuality} />}
            {weeklyWeathers && (
                <Box>
                    <List>
                        <ListItem sx={{ p: 0, height: 15, mb: -1.5, mt: 1 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <Typography sx={{ width: "5.3125rem" }}></Typography>
                                <Box sx={{ width: "5.0rem" }}>
                                    <Typography sx={{ fontSize: "0.75rem", color: "grey", pl: "0.8125rem" }}>오전</Typography>
                                </Box>
                                <Box sx={{ width: "5.625rem" }}>
                                    <Typography sx={{ fontSize: "0.75rem", color: "grey", pl: "0.8125rem" }}>오후</Typography>
                                </Box>
                                <Typography sx={{ fontSize: "0.75rem", width: "1.8125rem", color: "grey" }}>최저</Typography>
                                <Typography sx={{ fontSize: "0.75rem", width: "1.8125rem", color: "grey" }}>최고</Typography>
                            </Box>
                        </ListItem>
                        {weeklyWeathers.map((weeklyWeather, index) => (
                            <ListItem key={index} sx={{ p: 0, height: 40 }}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                    <Typography sx={{ fontSize: "0.9375rem", width: "5.3125rem", fontFamily: "Pretendard-Regular", fontWeight: "500" }}>
                                        {weeklyWeather.day}
                                        <small style={{ fontSize: "0.625rem", fontWeight: "200" }}>
                                            ({convertToDateTimeWithoutYearAndTime(weeklyWeather.date)})
                                        </small>
                                    </Typography>
                                    <WeeklyWeatherItem
                                        rainfall={weeklyWeather.amRainfall}
                                        skyStatus={weeklyWeather.amSkyStatus}
                                        skyStatusImageUrl={weeklyWeather.amSkyStatusImageUrl}
                                    />
                                    <WeeklyWeatherItem
                                        rainfall={weeklyWeather.pmRainfall}
                                        skyStatus={weeklyWeather.pmSkyStatus}
                                        skyStatusImageUrl={weeklyWeather.pmSkyStatusImageUrl}
                                    />
                                    <Typography sx={{ fontSize: "1.0rem", width: "1.875rem", pl: "0.625rem" }}>
                                        {weeklyWeather.minTemperature.match(/-?\d+°/)[0]}{" "}
                                    </Typography>
                                    <Typography sx={{ fontSize: "1.0rem" }}>{weeklyWeather.maxTemperature.match(/-?\d+°/)[0]} </Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </Box>
    );
}
